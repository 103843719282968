<template>
  <div id="mailVerfication">
    <Verification title="Mail bestätigt!" :isVerified="true">
      Deine E-Mail Adresse wurde bestätigt! Vergiss nicht, deinen Discord Account zu verifizieren, falls du das noch nicht gemacht hast.
    </Verification>
  </div>
</template>

<script>
import Verification from "../../components/SiteComponents/Verification";

export default {
  name: "MailVerficiation",
  components:{Verification}
}
</script>

<style scoped>

</style>